<template>
  <div class="AuxiliaryReading">
    <heade></heade>
    <div class="con_wrap">
      <div class="con_box">
        <el-button
          type="text"
          icon="el-icon-arrow-left"
          @click="$router.back(-1)"
          >返回上一页</el-button
        >
        <div class="text nice-scroll" v-html="articleh.content"></div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import { scroll } from "@utils/scroll";
import heade from "@/components/heade.vue";
import foot from "@/components/foot.vue";
import { getarticleh } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  data() {
    return {
      articleh: {},
    };
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
  mounted: function () {
    let that = this;
    getarticleh({ id: 7 }).then(function (res) {
      that.$set(that, "articleh", res.data);
    });
    window.onresize = function () {
      return (function () {
        that.$router.go(0);
      })();
    };
    scroll($(".nice-scroll"));
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style>
.AuxiliaryReading .con_wrap {
  width: 100%;
  height: 1280px;
  background: url(../../assets/images/fuzhu.jpg);
  background-size: cover;
  background-position: center 0;
}
.AuxiliaryReading .con_box {
  width: 1314px;
  height: 914px;
  position: relative;
  top: 200px;
  padding-bottom: 50px;
  margin: 0 auto;
  box-sizing: border-box;
}
.AuxiliaryReading .con_box .text {
  position: relative;
  height: 100%;
}
.el-button--text {
  color: rgb(121, 0, 0);
}
.text::-webkit-scrollbar {
  width: 0 !important;
}
.text > img {
  width: 100%;
  height: 100%;
}
</style>